import React, { useState, useEffect } from 'react';
import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Stack,
	Switch,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';

import Formatter from './Formatter';
import Tooltip from '../ui/Tooltip';
import { checkCondition, performCalculation } from './checkConditionANDperformCalculation';
import RemoveTimeZone from './RemoveTimeZone';

export default function DashboardModal_Preview_Table(props) {
	const [calculatedData, setCalculatedData] = useState(null);

	/**
	 * combines the actual data with the calculated data into one array of objects to be parsed by the table below
	 * @param {*} sheet
	 * @returns
	 */
	const calculatingData = (sheet) => {
		const tempData = [];
		if (sheet?.include) {
			// Sort calculatedColumns to ensure dependencies are calculated first
			// sheet.calculatedColumns.sort((a, b) => {
			// 	if (a.ruleName === 'Report Date') return -1;
			// 	if (b.ruleName === 'Report Date') return 1;
			// 	// Add additional sorting logic if you have more dependencies
			// 	return 0;
			// });

			sheet.data.map((row, rowIndex) => {
				if (rowIndex !== 0) {
					const tempRow = {};
					const tempCalculatedData = {};
					const tempCalculatedDataAarray = [];

					//check headers to see if column is included
					sheet.headers.forEach((header, headerIndex) => {
						if (header.include) {
							for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
								if (headerIndex === cellIndex) {
									if (header.type === 'Date') tempRow[header.name] = RemoveTimeZone(row[cellIndex]);
									else tempRow[header.name] = row[cellIndex];
									if (header.phiKey) tempRow.key = row[cellIndex]; //save phiKey as 'key'
								}
							}
						}
					});

					/**
					 * perform custom calculations on each row of data
					 * only performs calculations if data exists
					 */
					sheet?.calculatedColumns?.map((column) => {
						// console.log('Calculating:', column.ruleName);
						// console.log('Before Calculation tempCalculatedData:', tempCalculatedData);
						// console.log(!column?.conditions);
						// console.log('column', column);

						if (!column?.conditions || checkCondition(column.conditions, { ...tempRow, ...tempCalculatedData })) {
							const response = performCalculation(
								column.calculation,
								{ ...tempRow, ...tempCalculatedData },
								sheet.sheetName,
								props.ReportDate
							);

							// console.log('Calculation Response:', response);

							if (!tempCalculatedData?.[column.ruleName]) {
								tempCalculatedData[column.ruleName] = response;
								tempCalculatedDataAarray.push(response);
							}
						}

						// console.log('After Calculation tempCalculatedData:', tempCalculatedData);
					});

					tempData.push([tempCalculatedDataAarray]);
				}
			});
		}
		return tempData;
	};

	useEffect(() => {
		if (props?.ReportDate) setCalculatedData(calculatingData(props.sheet));
	}, [props.ReportDate, props.sheet.headers]);

	return (
		<>
			{calculatedData && (
				<Stack mb={4}>
					{/* sheet name */}
					<Typography variant='h5' color='primary' align='center' mr={5}>
						{props.sheet.sheetName}
					</Typography>

					{/* expected sheet name and toggle */}
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Stack direction='row' sx={{ width: '25%' }} alignItems='center'>
							{/* expectedSheetName selection */}
							<FormControl
								fullWidth
								size='small'
								disabled={!props.sheet.include}
								error={props.sheet.include && props.sheet.expectedSheetName === '' ? true : false}
							>
								<InputLabel id='sheet-select-label'>Expected Sheet Name</InputLabel>
								<Select
									labelId='sheet-select-label'
									id='sheet-select'
									value={props.sheet.expectedSheetName}
									label='Expected Sheet Name'
									onChange={(e) => {
										props.dashboard.expectedSheets.map((sheet, expectedSheetIndex) => {
											if (sheet.name === e.target.value)
												props.onExpectedSheetNameChange(props.sheetIndex, e.target.value, expectedSheetIndex);
										});
									}}
								>
									{props.dashboard.expectedSheets.map((sheet, sheetIndex) => {
										return (
											<MenuItem value={sheet.name} key={sheetIndex}>
												{sheet.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							{/* switch to control sheet on/off */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Tooltip text='Toggle Sheet On and Off' placement='right'>
									<Switch
										onChange={(e) => {
											props.onSheetSwitchChange(props.sheetIndex);
										}}
										name={`${props.sheetIndex}`}
										checked={props.sheet.include}
										size='small'
										color='primary'
										sx={{ margin: 'auto' }}
									/>
								</Tooltip>
							</Box>
						</Stack>
					</Box>

					{props?.sheet?.include && (
						<Table>
							{/* dropdown selection and switches for columns */}
							<TableHead>
								<TableRow>
									{props.sheet.headers.map((header, headerIndex) => (
										<TableCell key={headerIndex}>
											{/* expected field dropdown selection */}
											<FormControl
												fullWidth
												size='small'
												disabled={!header.include}
												error={header.include && header.name === '' ? true : false}
											>
												<InputLabel id='header-select-label'>Expected Field</InputLabel>
												<Select
													labelId='header-select-label'
													id='header-select'
													value={header.name}
													label='Expected Field'
													onChange={(e) => {
														props.onHeaderChangeName(e.target.value, headerIndex, props.sheetIndex);
													}}
												>
													{props.sheet.headers.map((header, i) => {
														return (
															<MenuItem value={header.name} key={i}>
																{header.name}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>

											{/* switch to control column on/off */}
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<Tooltip text='Toggle Column On and Off' placement='bottom'>
													<Switch
														onChange={(e) => {
															props.onHeaderSwitchChange(headerIndex, props.sheetIndex);
														}}
														name={`${headerIndex}`}
														checked={header.include}
														size='small'
														color='primary'
														sx={{ margin: 'auto' }}
													/>
												</Tooltip>
											</Box>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							{/* header row */}
							<TableHead>
								<TableRow sx={{ backgroundColor: '#a6a6a6' }}>
									{props.sheet.data[0].map((cell, cellIndex) => (
										<TableCell key={cellIndex} sx={{ textAlign: 'center' }}>
											<Typography variant='button'>{cell}</Typography>
										</TableCell>
									))}

									{/* Calculated Columns (Rules) Headers */}
									{props?.sheet?.calculatedColumns?.map((rule, ruleIndex) => (
										<TableCell key={ruleIndex}>{rule.ruleName}</TableCell>
									))}
								</TableRow>
							</TableHead>

							{/* table body - displays first 5 rows after the header row */}
							<TableBody>
								{props?.sheet?.data?.slice(1, 6).map((row, rowIndex) => (
									<TableRow key={rowIndex} sx={{ backgroundColor: rowIndex % 2 === 0 ? '#f2f2f2' : '' }}>
										{row.map((cell, cellIndex) => (
											<TableCell key={cellIndex} align='center'>
												{props.sheet.headers[cellIndex].include
													? Formatter(props.sheet.headers, cellIndex, cell)
													: null}
											</TableCell>
										))}

										{/* Fill in any missing cells with empty TableCell components */}
										{Array(props.sheet.data[0].length - row.length)
											.fill(null)
											.map((_, index) => (
												<TableCell key={`empty-${index}`} />
											))}

										{/* Calculated Columns (Rules) for each row */}
										{calculatedData[rowIndex]?.map((calculatedRow, calculatedRowIndex) =>
											calculatedRow.map((cell, cellIndex) => (
												<TableCell key={cellIndex} align='center'>
													{typeof cell === 'object' && cell instanceof Date ? cell.toLocaleDateString() : String(cell)}
												</TableCell>
											))
										)}
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</Stack>
			)}
		</>
	);
}
